@import "variables";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  color: $mainHighlightColor;
  font-weight: 600;
  text-decoration: none;
}

body {
  background: $mainBackground;
  font-family: $mainFont;
  color: $mainLightColor;
  padding: 0 20px;
  font-weight: lighter;
}

.mainConsoleList {
  list-style: none;
  li {
    height: 20px;
    &.importantLine {
      color: $mainHighlightColor;
      font-weight: 400;
    }
    &.errorLine {
      color: $errorHighlightColor;
      font-weight: 600;
    }
    &.warningLine {
      color: $warningHighlightColor;
      font-weight: 400;
    }
  }
  .consoleLineHeader {
    color: $secondaryLightColor;
  }

  .lineCursor {
    animation: cursorBlink infinite 1s linear;
  }
}

.app {
  .hiddenInput {
    width: 0;
    height: 0;
    border: 0;
    outline: 0;
  }
}

@keyframes cursorBlink {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}